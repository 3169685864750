<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('roles.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf blue" v-if="$auth.user().rights.admins_crud" @click="$router.push({name: 'roles-create'})">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t('roles.add-role') }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="roles.length">
				<div class="row-space-tbf header-count-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="text-list-length">
							{{ $t('general.list_length') }} <span class="number">{{ totalResults }}</span>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy == 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-users">
							<div class="text">{{ $t('general.users') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-responsibilities">
							<div class="text">{{ $t('roles.responsibilities') }}</div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="role in roles">
					<div class="row-space-tbf row-list-item" :key="role.id">
						<div class="space-left"><icon-role v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-name link" @click="showRole(role.id)">
								<div class="text">{{ role.name }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-users">
								<div class="text">{{ role.users }}</div>
							</div>
							<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-responsibilities">
								<div class="text">{{ role.responsibilities }}</div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+role.id" :id="'dropdownEdit'+role.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ role.id " :aria-labelledby="'dropdownEdit'+role.id">
										<div class="dropdown-item" @click="showRole(role.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="editRole(role.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'role', from: 'index_roles', model: role})"><div class="simple-text">{{$t('general.delete')}}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+role.id" :id="'dropdownEdit'+role.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ role.id " :aria-labelledby="'dropdownEdit'+role.id">
									<div class="dropdown-item" @click="showRole(role.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
									<div class="dropdown-item" @click="editRole(role.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'role', from: 'index_roles', model: role})"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.roles_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'roles-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('roles.create-new-role') }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconRole from '../../Icons/Role'
    import IconEdit from '../../Icons/EditDots'
	import IconEditMobile from '../../Icons/Edit'
	import IconClose from '../../Icons/Close'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
            IconSearch,
            IconPlus,
            IconArrow,
            IconRole,
            IconEdit,
			IconEditMobile,
			IconClose,
           	LoaderItemsList, 
			LoaderHeaderList,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	roles: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				totalResults: 0
            }
        },
        async mounted(){
        	if(!this.$auth.user().rights.admins_instance_crud){
        		this.$router.push({name: 'forbbiden'})
        	}

			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

        	await this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('general.roles');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRolesIndex', () => {
				this.checkQueryFilters()
				this.searchFilterFunct()
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
		computed: {

        },
        methods: {
			infiniteUsersHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

        		axios.get(this.$auth.user().master_instance_id + '/roles', {params: paramsCall})
        		.then(({data}) => {
        			this.totalResults = data.total_results
        			if(data.data.length){
        				this.page++
        				this.roles.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
							if(data.data.length < 20){
								$state.complete()
							}
					}else{
						$state.complete()
					}
					this.loadedList = true

        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
				});
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;

				this.searchFilterFunct()
			},
			showRole(roleSlug){
				this.$router.push({ name: 'roles-show', params: {id: roleSlug} })
			},
			editRole(roleSlug){
				this.$router.push({ name: 'roles-edit', params: {id: roleSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.roles = []
				this.infiniteId += 1
			}
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss" scoped>
	.list-goals{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.text{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
		}
		.column-users{
			flex: 0 0 200px;
		}
		.column-responsibilities{
			flex: 0 0 150px;
		}
	}
	.dropdown-item {
		&.disabled{
			color: #AFB1B8 !important;
		}
	}
</style>